import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import "./form.scss";
import { TFunction } from "i18next";
import { MdMessage } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";
import { RxReset } from "react-icons/rx";
import { BiSolidUserBadge } from "react-icons/bi";
import config from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CodeGenerator } from "../../../helpers/Coder";
import { ThreeCircles } from "react-loader-spinner";

const MAIL_ENDPOINT: string = "Mail";

type FormValues = {
  name: string;
  email: string;
  message: string;
};

const ContactForm: React.FC<{ t: TFunction }> = ({ t }) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const onReset = () => {
    reset();
  };

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    const sendEmail = async () => {
      setLoadingState(true);
      data.name = data.name.replace(/\s+/g, "");
      var code = CodeGenerator.generateKey();
      await fetch(`${(config as any).apiUrl}/${MAIL_ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          code,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setLoadingState(false);
          toast.success(t("Contact.ContactForm.MailSend"));
          reset();
        })
        .catch((error: Error) => {
          setLoadingState(false);
          toast.error(t("Contact.ContactForm.Errors.MailSend"));
          reset();
        });
    };
    sendEmail();
  };

  return (
    <>
      {!loadingState ? (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="input full">
            <input
              className="contact__input"
              autoComplete="off"
              placeholder=""
              {...register("name", {
                required: t("Contact.ContactForm.Errors.NameRequired"),
              })}
            />
            <label>{t("Contact.ContactForm.FirstName")}</label>
            <BiSolidUserBadge className="contact__icon" />
            {errors.name && (
              <span className="error">{errors.name?.message}</span>
            )}
          </div>

          <div className="input full">
            <input
              className="contact__input"
              autoComplete="off"
              placeholder=""
              {...register("email", {
                required: t("Contact.ContactForm.Errors.EmailRequired"),
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t("Contact.ContactForm.Errors.InvalidEmail"),
                },
              })}
            />
            <label>{t("Contact.ContactForm.Email")}</label>
            <MdMessage className="contact__icon" />
            {errors.email && (
              <span className="error">{errors.email?.message}</span>
            )}
          </div>

          <div className="input full">
            <textarea
              className="contact__input"
              autoComplete="off"
              placeholder=""
              {...register("message", {
                required: t("Contact.ContactForm.Errors.MessageRequired"),
              })}
            />
            <label>{t("Contact.ContactForm.Message")}</label>
            <MdMessage className="contact__icon" />
            {errors.message && (
              <span className="error">{errors.message?.message}</span>
            )}
          </div>

          <div className="input buttons">
            <button className="button" type="reset" onClick={() => onReset()}>
              {t("Contact.ContactForm.Reset")}
              <RxReset className="icon" />
            </button>
            <button className="button" type="submit">
              {t("Contact.ContactForm.Submit")}
              <GiConfirmed className="icon" />
            </button>
          </div>
        </form>
      ) : (
        <ThreeCircles
          visible={true}
          height="200"
          width="200"
          color="#0d7bda"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      )}

      <ToastContainer />
    </>
  );
};

export default ContactForm;
