import TestimonialImage from "../../assets/testimonial.jpg";
import { useTranslation } from "react-i18next";
import "./testimonial.scss";
import { motion } from "framer-motion";
import { createVariantProperties } from "../../variants/show-variants";

const Testimonial = () => {
  const { t } = useTranslation();
  const { variants, viewport } = createVariantProperties();
  return (
    <div className="section__testimonial">
      <div className="img__container">
        <img src={TestimonialImage} alt="testimonial" />
      </div>
      <motion.div
        variants={variants}
        className="testimonial__content"
        initial="initial"
        whileInView="animate"
        viewport={viewport}
      >
        <h2>{t("Testimonial.Title")}</h2>
        <p>{t("Testimonial.Part1")}</p>
        <p>{t("Testimonial.Part2")}</p>
      </motion.div>
    </div>
  );
};

export default Testimonial;
