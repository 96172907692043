import { useState } from "react";
import "./sidebar.scss";
import Links from "./links/Links";
import { Variants, motion } from "framer-motion";
import ToggleButton from "./toggleButton/ToggleButton";
import { linksData } from "./links";

export interface SideBardMotionVariant extends Variants {
  open: any;
  closed: any;
}

const variants: SideBardMotionVariant = {
  open: {
    x: 0,
    width: "300px",
    backgroundColor: "#ffffff",
    transition: {
      type: "spring",
      stiffness: 30,
    },
  },
  closed: {
    x: "-300px",
    width: "300px",
    backgroundColor: "#ffffff",
    transition: {
      type: "spring",
      stiffness: 30,
    },
  },
};

const Sidebar: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      className="sidebar"
      initial={false}
      animate={open ? "open" : "closed"}
    >
      <motion.div className="bg" variants={variants}>
        <Links links={linksData} />
      </motion.div>
      <ToggleButton setOpen={setOpen} />
    </motion.div>
  );
};

export default Sidebar;
