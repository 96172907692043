import { useEffect, useState } from 'react'
import "./scroll.scss";
import { FaArrowAltCircleUp } from "react-icons/fa";

const ScrollToTop: React.FC = (): JSX.Element => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
      if (window.scrollY > 560) {
        setShowScroll(true);
      } else if (window.scrollY <= 560) {
        setShowScroll(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', checkScrollTop);
      return () => {
        window.removeEventListener('scroll', checkScrollTop);
      };
    }, [showScroll]);

  return (
    <a href="#Home" className={`scroll__up ${showScroll ? 'show-scroll' : ''}`}>
        <FaArrowAltCircleUp className='scroll__up__icon'/>
    </a>
  )
}

export default ScrollToTop