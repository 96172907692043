import { TFunction } from "i18next";

export interface AccordionDataItem {
  id: number;
  title: string;
  renderContent: (t: TFunction) => JSX.Element;
}

export const accordionData: AccordionDataItem[] = [
  {
    id: 0,
    title: "Faq.Accordion.Title.title0",
    renderContent: (t: TFunction) => (
      <>
        <p>{t("Faq.Accordion.Content.content0")}</p>
        <br></br>
      </>
    ),
  },
  {
    id: 1,
    title: "Faq.Accordion.Title.title1",
    renderContent: (t: TFunction) => (
      <>
        <p>{t("Faq.Accordion.Content.content1_title")}</p>
        <br></br>
        <ul className="accordion__list">
          {Array.from({ length: 10 }).map((index, i) => (
            <li key={i} className="accordion__list__item">
              {t(`Faq.Accordion.Content.content1_${i}`)}
            </li>
          ))}
        </ul>
        <br></br>
      </>
    ),
  },
  {
    id: 2,
    title: "Faq.Accordion.Title.title2",
    renderContent: (t: TFunction) => (
      <>
        <p>{t("Faq.Accordion.Content.content2")}</p>
        <br></br>
      </>
    ),
  },
  {
    id: 3,
    title: "Faq.Accordion.Title.title3",
    renderContent: (t: TFunction) => (
      <>
        {Array.from({ length: 3 }).map((index, i) => (
          <div key={i}>
            <p>{t(`Faq.Accordion.Content.content3_${i}`)}</p>
            <br></br>
          </div>
        ))}
      </>
    ),
  },
  {
    id: 4,
    title: "Faq.Accordion.Title.title4",
    renderContent: (t: TFunction) => (
      <>
        {Array.from({ length: 3 }).map((index, i) => (
          <div key={i}>
            <p>{t(`Faq.Accordion.Content.content4_${i}`)}</p>
            <br></br>
          </div>
        ))}
      </>
    ),
  },
  {
    id: 5,
    title: "Faq.Accordion.Title.title5",
    renderContent: (t: TFunction) => (
      <>
        {Array.from({ length: 2 }).map((index, i) => (
          <div key={i}>
            <p>{t(`Faq.Accordion.Content.content5_${i}`)}</p>
            <br></br>
          </div>
        ))}
      </>
    ),
  },
  {
    id: 6,
    title: "Faq.Accordion.Title.title6",
    renderContent: (t: TFunction) => (
      <>
        <p>{t("Faq.Accordion.Content.content6_0")}</p>
        <br></br>
        <p>{t("Faq.Accordion.Content.content6_1")}</p>
        <br></br>
      </>
    ),
  },
  {
    id: 7,
    title: "Faq.Accordion.Title.title7",
    renderContent: (t: TFunction) => (
      <>
        <p>{t("Faq.Accordion.Content.content7_0")}</p>
        <br></br>
        <p>{t("Faq.Accordion.Content.content7_1")}</p>
        <br></br>
      </>
    ),
  },
  {
    id: 8,
    title: "Faq.Accordion.Title.title8",
    renderContent: (t: TFunction) => (
      <>
        <p>{t("Faq.Accordion.Content.content8_0")}</p>
        <br></br>
        <p>{t("Faq.Accordion.Content.content8_1")}</p>
        <br></br>
      </>
    ),
  },
];
