import { motion } from "framer-motion";
import { useState } from "react";
import { SideBardMotionVariant } from "../Sidebar";
import "./toggleButton.scss";

export interface ToggleButtonProperties {
  setOpen: (prev: any) => any;
}

const firstLineVariant: SideBardMotionVariant = {
  open: { d: "M 3 16.5 L 17 2.5" },
  closed: { d: "M 2 2.5 L 20 2.5" },
};

const secondLineVariant: SideBardMotionVariant = {
  open: {
    opacity: 0,
  },
  closed: { opacity: 1 },
};

const thirdLineVariant: SideBardMotionVariant = {
  open: { d: "M 3 2.5 L 17 16.346" },
  closed: { d: "M 2 16.346 L 20 16.346" },
};

const ToggleButton = ({ setOpen }: ToggleButtonProperties): JSX.Element => {
  const toggleMenu = () => {
    setOpen((prev: any) => !prev);
  };

  return (
    <button className="toggle__button" onClick={() => toggleMenu()}>
      {" "}
      <svg width="23" height="23" viewBox="0 0 23 23">
        <motion.path
          strokeWidth="3"
          strokeLinecap="round"
          variants={firstLineVariant}
        />
        <motion.path
          strokeWidth="3"
          strokeLinecap="round"
          d="M 2 9.423 L 20 9.423"
          variants={secondLineVariant}
        />
        <motion.path
          strokeWidth="3"
          strokeLinecap="round"
          variants={thirdLineVariant}
        />
      </svg>
    </button>
  );
};

export default ToggleButton;
