import { useState } from "react";
import "./toggler.scss";
import { useTranslation } from "react-i18next";

const LanguageToggler: React.FC = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    window.location.reload();
  };

  return (
    <div className="radio__elements">
      <label className="radio">
        <input
          type="radio"
          name="radio"
          checked={selectedLanguage === "pl-PL"}
          onChange={() => handleChange("pl-PL")}
        />
        <span className="name">PL</span>
      </label>
      <label className="radio">
        <input
          type="radio"
          name="radio"
          checked={selectedLanguage !== "pl-PL"}
          onChange={() => handleChange("en")}
        />
        <span className="name">EN</span>
      </label>
    </div>
  );
};

export default LanguageToggler;
