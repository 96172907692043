import "./home.scss";
import MainVideo from "../../assets/landing-video.mp4";
import { Variants, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { TypeAnimation } from "react-type-animation";
import { useEffect, useState } from "react";

export interface TextVariants extends Variants {
  initial: { x: number; opacity: number; transition?: any };
  animate: { x: number; opacity: number; transition?: any };
}

const textVariants: TextVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Home: React.FC = (): JSX.Element => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const { t } = useTranslation();
  const texts = Array.from({ length: 12 }, (_, i) => [
    t(`Home.slidingText.text${i}`),
    1250,
  ]).flat();

  const scrollToGivenSection = (sectionName: string) => {
    const element = document.getElementById(`${sectionName}`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    // Simulating page load completion after 2 seconds
    const timeout = setTimeout(() => {
      setPageLoaded(true);
    }, 2250);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="section__video">
      {pageLoaded && (
        <motion.div
          className="home__content"
          variants={textVariants}
          initial="initial"
          animate="animate"
        >
          <motion.div className="company__container">
            <motion.h1>{t("Home.companyFirst")}</motion.h1>
            <motion.h1 className="header__hover">
              {t("Home.companySecond")}
            </motion.h1>
          </motion.div>
          <motion.h2>
            <TypeAnimation
              speed={10}
              deletionSpeed={40}
              sequence={texts}
              repeat={Infinity}
            />
          </motion.h2>
          <motion.div className="home__buttons">
            <motion.button
              className="contact__button"
              onClick={() => scrollToGivenSection("Contact")}
            >
              {t("Home.contactButton")}
            </motion.button>
            <motion.button
              className="offer__button"
              onClick={() => scrollToGivenSection("Services")}
            >
              {t("Home.offerButton")}
            </motion.button>
          </motion.div>
        </motion.div>
      )}
      <video src={MainVideo} autoPlay muted loop />
    </div>
  );
};

export default Home;
