import { useRef } from "react";
import "./services.scss";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { FaLock } from "react-icons/fa";
import Card from "../Card/Card";
import { createVariantProperties } from "../../variants/show-variants";

const Services: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const { variants, viewport } = createVariantProperties();

  return (
    <motion.div
      ref={sectionRef}
      className="services__section"
      variants={variants}
      initial="initial"
      whileInView="animate"
      viewport={viewport}
      style={{ opacity: 0 }}
    >
      <motion.div className="text__container" variants={variants}>
        <p>
          {t("Services.LineText1")}
          <br /> {t("Services.LineText2")}
        </p>
        <hr />
      </motion.div>
      <motion.div className="title__container" variants={variants}>
        <div className="title">
          <h1>
            <motion.b>{t("Services.Title1")}</motion.b> {t("Services.Title2")}
          </h1>
          <button>{t("Services.WhatWeDo")}</button>
        </div>
        <div className="title">
          <h1>
            <motion.b>{t("Services.Title3")}</motion.b> {t("Services.Title4")}.
          </h1>
        </div>
      </motion.div>
      <Swiper
        loop={true}
        spaceBetween={50}
        slidesPerView={3}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[Pagination]}
        breakpoints={{
          1: {
            slidesPerView: 1,
          },
          930: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 48,
          },
        }}
        className="services__container"
      >
        {Array.from({ length: 4 }, (_, i) => (
          <SwiperSlide key={i + 1} className="service__card">
            <Card
              cardData={{
                title: `Services.Services.Service${i}.Title`,
                titleIcon: FaLock,
                renderContent: () => (
                  <p>{t(`Services.Services.Service${i}.Description`)}</p>
                ),
              }}
              t={t}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  );
};

export default Services;
