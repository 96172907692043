import "./contact.scss";
import ContactForm from "./contactForm/ContactForm";
import { useTranslation } from "react-i18next";
import ContactImg from "../../assets/contact.jpg";
import { motion } from "framer-motion";
import { createVariantProperties } from "../../variants/show-variants";

const Contact: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { variants, viewport } = createVariantProperties();

  return (
    <>
      <div className="section__contact">
        <div className="contact__container">
          <motion.div
            className="contact__info"
            variants={variants}
            initial="initial"
            whileInView="animate"
            viewport={viewport}
          >
            <h1>
              {t("Contact.Title")}
              <span>.</span>
            </h1>
            <p className="info">
              {t("Contact.Description")}
              <a href="mailto:info@netaudit.com.pl">info@netaudit.com.pl / </a>
              <a href="tel:531979330">{t("Contact.PhoneNumber")}</a>
            </p>
            <div className="contact__form">
              <ContactForm t={t} />
            </div>
          </motion.div>
          <div className="contact__img">
            <img src={ContactImg} alt="Contact" />
          </div>
        </div>
        <footer className="footer">
          <p>© 2024 - {t("Footer.Content")}</p>
        </footer>
      </div>
    </>
  );
};

export default Contact;
