import React from "react";
import "./pricing.scss";
import { useTranslation } from "react-i18next";
import PricingList from "./PricingList/PricingList";
import { motion } from "framer-motion";
import { createVariantProperties } from "../../variants/show-variants";

const Pricing: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { variants, viewport } = createVariantProperties(true);

  return (
    <div className="section__pricing">
      <motion.div
        className="pricing__text"
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={viewport}
      >
        <h1>{t("Pricing.Title")}</h1>
        <h3>{t("Pricing.Description")}</h3>
      </motion.div>

      <PricingList />
    </div>
  );
};

export default Pricing;
