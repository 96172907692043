import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import Home from "./components/home/Home";
import { AnimatePresence } from "framer-motion";
import Loader from "./components/loader/Loader";
import Header from "./components/header/Header";
import Services from "./components/services/Services";
import { animateScroll as scroll } from "react-scroll";
import Consulting from "./components/consulting/Consulting";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Pricing from "./components/pricing/Pricing";
import Osint from "./components/osint/Osint";
import Testimonial from "./components/testimonial/Testimonial";
import Faq from "./components/faq/Faq";
import Contact from "./components/contact/Contact";

const sections = [
  "Home",
  "Services",
  "Osint",
  "Consulting",
  "Pricing",
  "Testimonial",
  "Faq",
  "Contact",
];

function App() {
  const [loaded, setLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1750);
  }, []);

  const scrollToSection = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= sections.length) {
      newIndex = sections.length - 1;
    }

    setCurrentIndex(newIndex);
    scroll.scrollTo(window.innerHeight * newIndex, {
      duration: 350,
      smooth: true,
    });
    navigate(`#${sections[newIndex]}`);
  };

  const handleScroll = (event: WheelEvent) => {
    event.preventDefault();
    const direction = event.deltaY > 0 ? 1 : -1;
    scrollToSection(currentIndex + direction);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowDown") {
      scrollToSection(currentIndex + 1);
    } else if (event.key === "ArrowUp") {
      scrollToSection(currentIndex - 1);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleScroll, {
        passive: false,
      });
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        scrollContainer.removeEventListener("wheel", handleScroll);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [currentIndex]);

  return (
    <>
      <AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>
      <Header />
      <main className="main" ref={scrollContainerRef}>
        <Routes>
          <Route path="/" element={<SectionWrapper />} />
        </Routes>
        <ScrollToTop />
      </main>
    </>
  );
}

const SectionWrapper = () => (
  <>
    <section id="Home" className="section home">
      <Home />
    </section>
    <section id="Services" className="section services">
      <Services />
    </section>
    <section id="Osint" className="section osint__services">
      <Osint />
    </section>
    <section id="Consulting" className="section consulting">
      <Consulting />
    </section>
    <section id="Pricing" className="section pricing">
      <Pricing />
    </section>
    <section id="Testimonial" className="section testimonial">
      <Testimonial />
    </section>
    <section id="Faq" className="section faq">
      <Faq />
    </section>
    <section id="Contact" className="section contact">
      <Contact />
    </section>
  </>
);

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
