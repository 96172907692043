import React, { useRef } from "react";
import "./consulting.scss";
import { useTranslation } from "react-i18next";
import ConsultingImage from "../../assets/consulting-wave.png";
import { FaArrowDown } from "react-icons/fa";
import { motion } from "framer-motion";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import ConsultingAnimation from "../../assets/Animation_consulting.json";
import { createVariantProperties } from "../../variants/show-variants";

const Consulting: React.FC = (): JSX.Element => {
  const consultingRef = useRef<LottieRefCurrentProps>(null);
  const { t } = useTranslation();
  const { variants, viewport } = createVariantProperties();
  const handleArrowScroll = () => {
    const pricingSection = document.getElementById("Pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="section__consulting">
      <motion.div
        className="consulting__content"
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={viewport}
      >
        <div className="content">
          <h1 className="hover__title">
            {t("Consulting.Title1")} {t("Consulting.Title2")}
          </h1>
          <div className="consulting__description">
            <p>{t("Consulting.Description")}</p>
            <FaArrowDown
              className="scroll__down"
              onClick={() => handleArrowScroll()}
            />
          </div>
        </div>
        <Lottie
          className="animation"
          animationData={ConsultingAnimation}
          onComplete={() => {
            consultingRef.current?.goToAndPlay(36, true);
          }}
          lottieRef={consultingRef}
          loop={false}
        />
      </motion.div>
      <img
        src={ConsultingImage}
        alt="Consulting"
        className="consulting__image"
      />
    </div>
  );
};

export default Consulting;
