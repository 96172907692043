import React, { useEffect, useState, useRef } from "react";
import { AccordionDataItem } from "./accordionData";
import { useTranslation } from "react-i18next";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./accordion.scss";
import { TFunction } from "i18next";

export interface AccordionItem extends AccordionDataItem {
  toggled: boolean;
}

const Accordion: React.FC<{
  items: AccordionDataItem[];
  keepOthersOpen: boolean;
}> = ({ items, keepOthersOpen }): JSX.Element => {
  const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([]);
  const { t } = useTranslation();

  const handleAccordionToggle = (accordionItem: AccordionItem) => {
    setAccordionItems([
      ...accordionItems.map((item) => {
        let toggled = item.toggled;

        if (accordionItem.id === item.id) {
          toggled = !item.toggled;
        } else if (!keepOthersOpen) {
          toggled = false;
        }

        return {
          ...item,
          toggled,
        };
      }),
    ]);
  };

  useEffect(() => {
    if (items) {
      setAccordionItems(
        items.map((item) => ({
          ...item,
          toggled: false,
        }))
      );
    }
  }, [items]);

  return (
    <div className="accordion">
      {accordionItems?.map((listItem: AccordionItem, index: number) => (
        <AccordionItemComponent
          key={index}
          listItem={listItem}
          t={t}
          handleAccordionToggle={handleAccordionToggle}
        />
      ))}
    </div>
  );
};

const AccordionItemComponent: React.FC<{
  listItem: AccordionItem;
  t: TFunction;
  handleAccordionToggle: (item: AccordionItem) => void;
}> = ({ listItem, t, handleAccordionToggle }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (listItem.toggled) {
      setHeight(`${contentRef.current?.scrollHeight}px`);
    } else {
      setHeight("0px");
    }
  }, [listItem.toggled]);

  return (
    <div className={`accordion__item ${listItem.toggled ? "toggled" : ""}`}>
      <button
        className="toggle__button"
        onClick={() => handleAccordionToggle(listItem)}
      >
        <p>{t(listItem.title)}</p>
        {!listItem.toggled ? <FaPlus /> : <FaMinus />}
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className="accordion__content"
      >
        {listItem.renderContent(t)}
      </div>
    </div>
  );
};

export default Accordion;
