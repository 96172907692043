import "./faq.scss";
import Accordion from "./accordion/Accordion";
import { accordionData } from "./accordion/accordionData";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import FaqAnimation from "../../assets/Animation_faq.json";
import { useRef } from "react";
import { motion } from "framer-motion";
import { createVariantProperties } from "../../variants/show-variants";

const Faq: React.FC = (): JSX.Element => {
  const faqAnimation = useRef<LottieRefCurrentProps>(null);
  const { variants, viewport } = createVariantProperties(true);

  return (
    <div className="section__faq">
      <motion.div
        className="container"
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={viewport}
      >
        <Accordion items={accordionData} keepOthersOpen={false} />
      </motion.div>
      <Lottie
        className="animation"
        onComplete={() => {
          faqAnimation.current?.goToAndPlay(206, true);
        }}
        lottieRef={faqAnimation}
        loop={false}
        animationData={FaqAnimation}
      />
    </div>
  );
};

export default Faq;
