import React from "react";
import "./osint.scss";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import OsintVideo from "../../assets/osint.mp4";
import Lottie from "lottie-react";
import OsintAnimation from "../../assets/Animation_osint.json";
import { createVariantProperties } from "../../variants/show-variants";

const Osint: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { variants, viewport } = createVariantProperties(true);

  return (
    <div className="section__osint">
      <motion.div
        className="osint__content"
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={viewport}
      >
        <motion.div className="osint__title">
          <h1 className="title__hover">
            {t("Osint.Title1")} {t("Osint.Title2")}
          </h1>
          <p>{t("Osint.Description")}</p>
        </motion.div>
        <Lottie className="animation" animationData={OsintAnimation} />
      </motion.div>
      <video src={OsintVideo} autoPlay muted loop />
    </div>
  );
};

export default Osint;
