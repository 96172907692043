import { Variants } from "framer-motion";

export interface DefaultVariant {
  opacity: number;
  x: number;
  y: number;
  transition?: { duration: number; staggerChildren: number };
}

export interface ShowUpVariant extends Variants {
  initial: DefaultVariant;
  animate: DefaultVariant;
}

export interface ShowUpViewPort {
  once: boolean;
  amount: number;
  margin: string;
}

export const createVariantProperties = (
  reversed: boolean = false
): { variants: ShowUpVariant; viewport: ShowUpViewPort } => {
  return {
    variants: {
      initial: { opacity: 0, x: reversed ? 50 : -50, y: 50 },
      animate: {
        opacity: 1,
        x: 0,
        y: 0,
        transition: {
          duration: 1,
          staggerChildren: 0.1,
        },
      },
    },
    viewport: { once: true, amount: 0.2, margin: "0px 0px -200px 0px" },
  };
};
