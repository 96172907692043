import React from "react";
import { IconType } from "react-icons";
import "./card.scss";

export interface CardData {
  title: string;
  titleIcon?: IconType;
  titleDescription?: string;
  renderContent: () => JSX.Element;
  renderCornerIconContent?: () => JSX.Element;
}

const Card: React.FC<{
  cardData: CardData;
  t: (key: string) => string;
}> = ({ cardData, t }) => {
  const {
    title,
    titleIcon: TitleIcon,
    titleDescription,
    renderContent,
    renderCornerIconContent,
  } = cardData;

  return (
    <div className="card__container">
      <div className="card__text">
        <div className="title">
          {TitleIcon && <TitleIcon />}
          <h3 className="card__title">{t(title)}</h3>
        </div>
        {titleDescription && (
          <p className="card__description">{titleDescription}</p>
        )}
      </div>

      <div className="card__content">{renderContent()}</div>
      {renderCornerIconContent && (
        <div className="card__corner__content">{renderCornerIconContent()}</div>
      )}
    </div>
  );
};

export default Card;
