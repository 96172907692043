import { IconType } from "react-icons";
import { FaHome, FaMoneyBillWaveAlt, FaQuestionCircle } from "react-icons/fa";
import { MdOutlineHomeRepairService, MdApproval } from "react-icons/md";
import { FaHeadset } from "react-icons/fa6";
import { IoIosContact } from "react-icons/io";
import { GiDualityMask } from "react-icons/gi";

export interface Link {
  id: number;
  url: string;
  translationName: string;
  icon: IconType;
}

export const linksData: Array<Link> = [
  {
    id: 1,
    url: "Home",
    translationName: "Sidebar.Menu.Links.Home",
    icon: FaHome,
  },
  {
    id: 2,
    url: "Services",
    translationName: "Sidebar.Menu.Links.Services",
    icon: MdOutlineHomeRepairService,
  },
  {
    id: 3,
    url: "Osint",
    translationName: "Sidebar.Menu.Links.Osint",
    icon: GiDualityMask,
  },
  {
    id: 4,
    url: "Consulting",
    translationName: "Sidebar.Menu.Links.Consulting",
    icon: FaHeadset,
  },
  {
    id: 5,
    url: "Pricing",
    translationName: "Sidebar.Menu.Links.Pricing",
    icon: FaMoneyBillWaveAlt,
  },
  {
    id: 6,
    url: "Testimonial",
    translationName: "Sidebar.Menu.Links.Testimonial",
    icon: MdApproval,
  },
  {
    id: 7,
    url: "Faq",
    translationName: "Sidebar.Menu.Links.Faq",
    icon: FaQuestionCircle,
  },
  {
    id: 8,
    url: "Contact",
    translationName: "Sidebar.Menu.Links.Contact",
    icon: IoIosContact,
  },
];
