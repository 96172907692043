import React from "react";
import { GiPodiumThird, GiPodiumSecond, GiPodiumWinner } from "react-icons/gi";
import { FaCheckCircle } from "react-icons/fa";
import { t } from "i18next";
import { IconType } from "react-icons";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import Card from "../../Card/Card";
import { createVariantProperties } from "../../../variants/show-variants";

export interface PricingPlan {
  id: string;
  icon: IconType;
  serviceCount: number;
  reversed?: boolean;
}

const plans: PricingPlan[] = [
  {
    id: "Basic",
    icon: GiPodiumThird,
    serviceCount: 3,
  },
  {
    id: "Pro",
    icon: GiPodiumSecond,
    serviceCount: 4,
    reversed: true,
  },
  {
    id: "Premium",
    icon: GiPodiumWinner,
    serviceCount: 7,
  },
];

const PricingList: React.FC = (): JSX.Element => {
  const { variants, viewport } = createVariantProperties(true);

  return (
    <motion.div
      className="pricing__list"
      variants={variants}
      initial="initial"
      whileInView="animate"
      viewport={viewport}
    >
      <Swiper
        className="pricing__slider"
        loop={true}
        spaceBetween={50}
        slidesPerView={3}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[Pagination]}
        breakpoints={{
          1: {
            slidesPerView: 1,
          },
          930: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 48,
          },
        }}
      >
        {plans.map(({ id, icon: Icon, serviceCount, reversed }) => (
          <SwiperSlide
            key={id}
            className={`pricing__card ${
              reversed ? "pricing__card__reversed" : ""
            }`}
          >
            <Card
              cardData={{
                title: `Pricing.${id}.Title`,
                titleIcon: Icon,
                titleDescription: `${t("Pricing.PriceDescription")} ${t(
                  `Pricing.${id}.Price`
                )}`,
                renderContent: () => (
                  <ul className="card__services">
                    {Array.from({ length: serviceCount }, (_, i) => (
                      <li className="card__service" key={i}>
                        <FaCheckCircle />
                        {t(`Pricing.${id}.Services.Service${i}`)}
                      </li>
                    ))}
                  </ul>
                ),
                renderCornerIconContent: () => (
                  <div className="pricing__contact">
                    <a href="#Contact">
                      <BiSolidMessageAltDetail />
                    </a>
                  </div>
                ),
              }}
              t={t}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  );
};

export default PricingList;
